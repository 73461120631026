import { combineLatest, from, Observable } from "rxjs";
import { map, switchMap, first, tap } from "rxjs/operators";
import { parseDate, tryParseDate, DfStrictParseFloat } from "./calculations.shared";
import resolveablePromise from "@/lib/resolvablePromise";
import type { BaseControlDefBase, BaseElementDefBase } from "../pages/elements/BaseElementDef";
import { CalculationSetting } from "@cs-ts/calculation-setting";
import { Operation } from "@cs-ts/operation";
import { Value } from "@cs-ts/value";
import { Constant } from "@cs-ts/constant";
import { BuiltinFunction } from "@cs-ts/builtin-function";
import { GroupListV2 } from "@cs-ts/group-list-v2";
import { Cast } from "@cs-ts/cast";
import type { CalculationContext } from "../pages/elements/__CalculationContextInterface";
import type FormDef from "../pages/elements/FormDef";
import { ICalculationSettingWithTerms } from "@/cs-ts-bridge/ts/i-calculation-setting-with-terms";
//import generateGuid from "@/lib/generateGuid";
import type Moment from "moment";

let debug = false;
export function setDebug(i: boolean) {
    debug = i;
}
export function getDebug() {
    return debug;
}
const breakpoint = (context: InnerCalculationContext) => {
    return debug || context.debug;
};

let _momentPromise: Promise<typeof Moment>; // TODO - replace with Luxon?
function fetchMoment() {
    if (_momentPromise == null) {
        _momentPromise = Promise.all([import(/* webpackChunkName: "Moment", webpackPrefetch: true */ "moment"), import(/* webpackChunkName: "Moment", webpackPrefetch: true */ "moment-timezone")] as const).then(result => result[0].default);
    }
    return _momentPromise;
}

type ScalarCalculationResult = string | number | boolean | null | undefined;
type ListCalculationResult = ScalarCalculationResult[];
type CalculationResult = ScalarCalculationResult | ListCalculationResult;

/** @returns {Promise<boolean>} */
export function resolveCalculationPromiseBool(calculation: CalculationSetting[], contextNode: BaseElementDefBase, debug?: boolean) {
    const result = resolveCalculation(calculation, contextNode, debug, Type.List)();
    return result
        .pipe(
            //@ts-ignore
            map(value => (value as string[]).reduce((a, i) => a || parseBool(i) === 1, false)),
            //@ts-ignore
            first(),
        )
        .toPromise();
}

type ValueOf<T> = T[keyof T];
type TypeValue = ValueOf<typeof Type>;
/** @returns {Promise<string[]>} */
export function resolveCalculationPromise(calculation: CalculationSetting[], contextNode: BaseElementDefBase, debug?: boolean, type?: TypeValue) {
    //@ts-ignore -- TODO
    return resolveCalculation(calculation, contextNode, debug, type)().pipe(first()).toPromise();
}

type InnerCalculationContext = CalculationContext & {
    node: BaseElementDefBase;
    rootCalc: CalculationSetting;
    debug: boolean;
    currentListRunningResult?: Observable<CalculationResult>[];
    currentListIndex?: number[];
    currentListItem?: CalculationResult[];
};

/** @returns {() => Observable<string[]>} */
export function resolveCalculation(calculationList: CalculationSetting[], contextNode: BaseElementDefBase, debug?: boolean, type?: TypeValue) {
    //throw new Error(JSON.stringify(i));
    const type2 = type != null ? type : Type.StringList;
    if (calculationList == null || calculationList.length == 0) {
        return () => from([[]]);
    }
    //const a = generateGuid();
    return () => _resolveCalculation(calculationList[0], { node: contextNode, rootCalc: calculationList[0], ...contextNode.calculationContext, debug: debug || false }, type2) /*.pipe(tap((i) => {
        console.log(`${a} - Trigger calc for ${contextNode.definition?.id} - ${calculationList[0].id}`);
    }))*/;
    //.pipe(
    //    map(i => {
    //        if (i == null) {
    //            return i;
    //        }
    //        //debugger;
    //        return i != null ? i.flat() : i;
    //    }),
    //);
}
export default resolveCalculation;

function _resolveCalculation(calculation: CalculationSetting, context: InnerCalculationContext, type: TypeValue): Observable<CalculationResult> {
    const result = _resolveCalculationRaw(calculation, context, type);
    //if (result.type) {
    //    result.result = result.result.pipe(tap(i => { let a = calculation; debugger; }));
    //} else {
    //    result = result.pipe(tap(i => { let a = calculation; debugger; }));
    //}
    if (result == null) throw new Error("AZ");
    return isCalculationInnerResult(result)
        ? convert(result, type, context).result
        : result.pipe(
              //switchMap(r => r),
              switchMap(r => {
                  const a = convert(r, type, context).result;
                  //debugger;
                  return a;
              }),
          );
}
function isCalculationInnerResult(i: CalculationInnerResult | CalculationPendingResult): i is CalculationInnerResult {
    //@ts-expect-error
    return !!i.type;
}
export function _resolveCalculationRaw(calculation: CalculationSetting, context: InnerCalculationContext, type?: TypeValue): CalculationInnerResult | CalculationPendingResult {
    const operatorFunc = operators[calculation.$type as keyof typeof operators];
    const result = operatorFunc != null ? operatorFunc(calculation, context) : { result: from(["TODO"]), type: Type.StringList, calculation: calculation as CalculationSetting };
    return result;
}
function _resolveCalculationTyped(calculation: CalculationSetting, context: InnerCalculationContext, type?: TypeValue): Observable<CalculationInnerResult> {
    const result = _resolveCalculationRaw(calculation, context, type);
    return isCalculationInnerResult(result)
        ? result.result.pipe(
              map(r => {
                  if (result == null) throw new Error("C");
                  return { result: from([r]), type: result.type, calculation } as CalculationInnerResult;
              }),
          )
        : result.pipe(
              //switchMap(r => r),
              map(r => {
                  return { result: r.result, type: r.type, calculation } as CalculationInnerResult;
              }),
          );
}
function convert(result: CalculationInnerResult, toType: TypeValue, context: InnerCalculationContext) {
    let convertItem: null | ((a: ScalarCalculationResult) => ScalarCalculationResult) = null;
    if (result.type == null) throw new Error("Z:" + JSON.stringify(result));
    if (toType != null && toType[0] != null && result.type[0] != toType[0]) {
        if (toType[0] == TypeType.String) {
            convertItem = i => (i == null ? null : i.toString());
        } else if (toType[0] == TypeType.Boolean) {
            convertItem = i => (i == null ? null : parseBool(i));
        } else if (toType[0] == TypeType.Decimal) {
            if (result.type[0] == TypeType.Boolean) {
                convertItem = i => (i == null ? null : i ? 1 : 0);
            } else if (result.type[0] == TypeType.String) {
                convertItem = i => (i == null ? null : DfParseFloatWithoutNan(i));
            } else if (result.type[0] == null) {
                convertItem = i => (i == null ? null : i === true ? 1 : i == false ? 0 : DfParseFloatWithoutNan(i));
            } else {
                throw new Error(`RT: ${result.type}`);
            }
        }
    }
    let convert = (i: CalculationResult) => i;
    if (toType != null && toType[1] != null && result.type[1] != toType[1]) {
        if (convertItem == null) {
            convertItem = i => i;
        }
        if (toType[1] == TypeStructure.List) {
            //@ts-ignore
            convert = i => [convertItem(i)];
        } else if (toType[1] == TypeStructure.Scalar) {
            //@ts-ignore
            convert = i => convertItem(i != null && i.length > 0 ? i[0] : null);
        }
    } else if (convertItem != null) {
        if (result.type[1] == TypeStructure.List) {
            //@ts-ignore
            convert = i => i.map(convertItem);
        } else if (result.type[1] == TypeStructure.Scalar) {
            //@ts-ignore
            convert = convertItem;
        }
    }
    //if (result.calculation.function) {
    //    throw new Error(JSON.stringify({ result, type: output}));
    //}
    //if (convert != null) {
    //    convert.calculation = result.calculation;
    //}
    if (convertItem != null) {
        //@ts-ignore
        convertItem.calculation = result.calculation;
    }
    return {
        type: toType,
        result: result.result.pipe(
            map(convert),
            //map(i => [i, convert(i)]),
            //tap(([b, a]) => {
            //    if (context.debug) {
            //        throw new Error(JSON.stringify(b) + " > " + JSON.stringify(a) + " `` " + JSON.stringify(result.type) + " > " + JSON.stringify(toType));
            //    }
            //}),
            //map(([b, a]) => a),
        ),
        calculation: result.calculation,
    };
}
const valueOperator = "MI.Common.DynamicForms.Controls.Settings.Value, DynamicForms";
const operators: Record<string, (calculation: CalculationSetting, context: InnerCalculationContext) => CalculationInnerResult | CalculationPendingResult> = {
    "MI.Common.DynamicForms.Controls.Settings.CurrentListRunningResult, DynamicForms"(calculation: CalculationSetting, context: InnerCalculationContext) {
        const stack = context.currentListRunningResult || [];
        const result = stack[stack.length - 1];
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.Scalar,
            result: result, // from([result]),
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.CurrentListIndex, DynamicForms"(calculation: CalculationSetting, context: InnerCalculationContext) {
        const stack = context.currentListIndex || [];
        const result = stack[stack.length - 1];
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.DecimalScalar,
            result: from([result]),
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.CurrentListItem, DynamicForms"(calculation: CalculationSetting, context: InnerCalculationContext) {
        const stack = context.currentListItem || [];
        const result = stack[stack.length - 1];
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.Scalar,
            result: from([result]),
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.Operation, DynamicForms"(calculation: Operation, context: InnerCalculationContext) {
        if (breakpoint(context)) debugger;
        switch (calculation.operator) {
            case "lcincludes": //cspell:ignore lcincludes
                return builtinFunc["IsListCasedIncludes"](calculation, context);
            case "lincludes": //cspell:ignore lincludes
                return builtinFunc["IsListBasicIncludes"](calculation, context);
            case "luincludes": //cspell:ignore luincludes
                return builtinFunc["IsListUncasedIncludes"](calculation, context);
            case "lnincludes": //cspell:ignore lnincludes
                return builtinFunc["IsListNumericIncludes"](calculation, context);
            case "ldincludes": //cspell:ignore ldincludes
                return builtinFunc["IsListDateIncludes"](calculation, context);
        }
        return operation[calculation.operator!](calculation, context);
    },
    "MI.Common.DynamicForms.Controls.Settings.BuiltinFunction, DynamicForms"(calculation: BuiltinFunction, context: InnerCalculationContext) {
        if (breakpoint(context)) debugger;
        return builtinFunc[calculation.function as keyof typeof builtinFunc](calculation, context);
    },
    "MI.Common.DynamicForms.Controls.Settings.Constant, DynamicForms"(calculation: Constant, context: InnerCalculationContext) {
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.StringScalar,
            result: from([calculation.value]),
            calculation,
        };
        return calcResult;
    },
    ////"MI.Common.DynamicForms.Controls.Settings.NullConstant, DynamicForms"(calculation: CalculationSetting, context: InnerCalculationContext) {
    ////    if (breakpoint(context)) debugger;
    ////    return {
    ////        type: Type.StringScalar,
    ////        result: from([null]),
    ////        calculation,
    ////    };
    ////},
    "MI.Common.DynamicForms.Controls.Settings.Value, DynamicForms"(calculation: Value, context: InnerCalculationContext) {
        const id = calculation.id!;
        const rootElement = context.node.calculationContext.rootElement!;
        //if (calculation.extraDataKey && calculation.extraDataKey != "null") {
        //    return makeBackendFunc(null)(calculation, context, null, calculation.id);
        //}
        function getValue(otherElements: BaseElementDefBase[]) {
            return otherElements.map(i => (calculation.extraDataKey && calculation.extraDataKey != "null" ? getExtraData(i) : (i as BaseControlDefBase).effectiveValueList$()));
        }
        function getExtraData(i: BaseElementDefBase) {
            const fallback = () => from(context.getOrCreateFormId()).pipe(switchMap(formId => (makeBackendFunc(null)(calculation, context, null, formId + calculation.id + calculation.extraDataKey) as CalculationInnerResult).result.pipe(map(i => i as string[]))));
            return i.effectiveExtraData$(calculation.extraDataKey!, fallback);
        }
        const result = rootElement.getElementsById$(id, context.node.vmId).pipe(
            switchMap(otherElements =>
                otherElements.length == 0
                    ? from([[]])
                    : combineLatest(getValue(otherElements)).pipe(
                          tap(i => {
                              if (breakpoint(context)) debugger;
                          }),
                          map(arraysOfOtherValues => Array.prototype.concat.apply([], arraysOfOtherValues)),
                          tap(i => {
                              if (breakpoint(context)) debugger;
                          }),
                      ),
            ),
        );
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.StringList,
            result,
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.ExternalData, DynamicForms"(calculation: CalculationSetting, context: InnerCalculationContext) {
        const id = calculation.id!;
        const rootElement = context.node.calculationContext.rootElement as FormDef;
        const value = rootElement.externalData[id] ?? [];
        if (breakpoint(context)) debugger;
        const calcResult: CalculationInnerResult = {
            type: Type.StringList,
            result: from([value]),
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.GroupListV2, DynamicForms"(calculation: GroupListV2, context: InnerCalculationContext) {
        const groupValue = calculation.group;
        let group = null;
        if (groupValue != null && groupValue.includes("`")) {
            group = groupValue.split("`")[1];
        }
        const calcResult: CalculationInnerResult = {
            type: Type.String,
            result: from([group]),
            calculation,
        };
        return calcResult;
    },
    "MI.Common.DynamicForms.Controls.Settings.Cast, DynamicForms"(calculation: Cast, context: InnerCalculationContext) {
        const calc = calculation.input!;
        const type = getTypeFromStr(calculation.dataType!, calculation.structureType!);
        const calcResult: CalculationInnerResult = {
            result: _resolveCalculation(calc, context, type),
            type: type,
            calculation,
        };
        return calcResult;
    },
};
export interface CalculationInnerResult {
    result: Observable<CalculationResult>;
    type: TypeValue;
    calculation: CalculationSetting;
}
export type CalculationPendingResult = Observable<CalculationInnerResult>;

const TypeType = {
    String: 0,
    Decimal: 1,
    Boolean: 2,
};
const TypeStructure = {
    Scalar: 0,
    List: 1,
};
const Type = {
    Undefined: [null, null],
    Scalar: [null, TypeStructure.Scalar],
    List: [null, TypeStructure.List],
    String: [TypeType.String, null],
    StringScalar: [TypeType.String, TypeStructure.Scalar],
    StringList: [TypeType.String, TypeStructure.List],
    Boolean: [TypeType.Boolean, null],
    BooleanScalar: [TypeType.Boolean, TypeStructure.Scalar],
    BooleanList: [TypeType.Boolean, TypeStructure.List],
    Decimal: [TypeType.Decimal, null],
    DecimalScalar: [TypeType.Decimal, TypeStructure.Scalar],
    DecimalList: [TypeType.Decimal, TypeStructure.List],
};
const getTypeFromStr = (dataType: string, structureType: string) => {
    let typeType = null;
    if (dataType == "String") {
        typeType = TypeType.String;
    } else if (dataType == "Decimal") {
        typeType = TypeType.Decimal;
    } else if (dataType == "Boolean") {
        typeType = TypeType.Boolean;
    }
    let typeStructure = null;
    if (structureType == "List") {
        typeStructure = TypeStructure.List;
    } else if (structureType == "Scalar") {
        typeStructure = TypeStructure.Scalar;
    }
    return [typeType, typeStructure];
};
const operation: Record<string, (c: ICalculationSettingWithTerms, c2: InnerCalculationContext) => CalculationInnerResult | CalculationPendingResult> = {
    "?:"(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext) {
        const operandTerm = getTerm(calculation, context)(0, Type.BooleanScalar);
        const branchTerms = [1, 2].map(i => getTypedTerm(calculation, context)(i, Type.Undefined)); // can't be lazy evaluated since that wouldn't get correct current-list-XX data
        const result = operandTerm.pipe(
            switchMap(resolvedTerm => {
                const operand = parseBool(resolvedTerm as string);
                const result = branchTerms[operand ? 0 : 1];
                if (breakpoint(context)) debugger;
                return result;
            }),
        );
        //@ts-expect-error TODO?
        result.calculation = calculation;
        return result;
    },
    "!"(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext) {
        const result = getTerm(calculation, context)(0, Type.BooleanScalar).pipe(
            map(resolvedTerm => {
                const operand = parseBool(resolvedTerm as string);
                if (breakpoint(context)) debugger;
                return !operand;
            }),
        );
        const calcResult: CalculationInnerResult = { result, type: Type.BooleanScalar, calculation: calculation as CalculationSetting };
        return calcResult;
    },
};
const operationInners = {
    STRING_STRING: {
        config: {
            input: Type.StringScalar,
            output: Type.StringScalar,
            multipleTerms: true,
            _convert: (v: string | null) => (v == null ? "" : v),
        },
        ".": (a: string | null, b: string | null) => operationInners.STRING_STRING.config._convert(a) + operationInners.STRING_STRING.config._convert(b),
    },
    STRING_LIST_BOOL: {
        config: {
            input: Type.StringList,
            output: Type.BooleanScalar,
            multipleTerms: false,
            _func(c: boolean, s: (a: string, b: string) => number, f: (a: string | null, b: string | null) => boolean | null, a: (string | null)[], b: (string | null)[]) {
                if (a == null || b == null) {
                    return null;
                }
                if (a.length != b.length) {
                    return !c;
                }
                a = a.sort(operationInners.STRING_LIST_BOOL.config._sort(s));
                b = b.sort(operationInners.STRING_LIST_BOOL.config._sort(s));
                for (let i = 0; i < a.length; i++) {
                    const r = f(a[i], b[i]);
                    if (r != c || r == null) {
                        return r;
                    }
                }
                return c;
            },
            _sort(f: (a: string, b: string) => number) {
                return (a: string | null, b: string | null) => {
                    return a == null ? (b == null ? 0 : -1) : b == null ? 1 : f(a, b);
                };
            },
        },
        eq: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                true,
                (a, b) => (a == b ? 0 : a < b ? 1 : -1),
                (a, b) => a == b,
                a,
                b,
            ),
        ne: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                false,
                (a, b) => (a == b ? 0 : a < b ? 1 : -1),
                (a, b) => a != b,
                a,
                b,
            ),
        ueq: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                true,
                (a, b) => (a.toUpperCase() == b.toUpperCase() ? 0 : a.toUpperCase() < b.toUpperCase() ? 1 : -1),
                (a, b) => a?.toUpperCase() == b?.toUpperCase(),
                a,
                b,
            ),
        une: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                false,
                (a, b) => (a.toUpperCase() == b.toUpperCase() ? 0 : a.toUpperCase() < b.toUpperCase() ? 1 : -1),
                (a, b) => a?.toUpperCase() != b?.toUpperCase(),
                a,
                b,
            ),
        deq: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                true,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                (a, b) => (tryParseDate(a)?.getTime() == tryParseDate(b)?.getTime() ? 0 : tryParseDate(a)?.getTime()! < tryParseDate(b)?.getTime()! ? 1 : -1),
                (a, b) => {
                    const aD = tryParseDate(a);
                    const bD = tryParseDate(b);
                    return aD != null && bD != null ? aD.getTime() == bD.getTime() : null;
                },
                a,
                b,
            ),
        dne: (a: (string | null)[], b: (string | null)[]) =>
            operationInners.STRING_LIST_BOOL.config._func(
                false,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                (a, b) => (tryParseDate(a)?.getTime() == tryParseDate(b)?.getTime() ? 0 : tryParseDate(a)?.getTime()! < tryParseDate(b)?.getTime()! ? 1 : -1),
                (a, b) => {
                    const aD = tryParseDate(a);
                    const bD = tryParseDate(b);
                    return aD != null && bD != null ? aD.getTime() != bD.getTime() : null;
                },
                a,
                b,
            ),
    },
    STRING_BOOL: {
        config: {
            input: Type.StringScalar,
            output: Type.BooleanScalar,
            multipleTerms: false,
            _func(f: (a: string, b: string) => boolean | null | undefined, a: string | null, b: string | null) {
                if (a == null || b == null) {
                    return null;
                }
                return f(a, b);
            },
        },
        includes: (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.includes(b), a, b),
        cincludes: (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.includes(b), a, b), //cspell:ignore cincludes
        uincludes: (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.toUpperCase().includes(b.toUpperCase()), a, b), //cspell:ignore uincludes
        "=~": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => DfNewRegExpWithoutError(b)?.test(a), a, b),
        "=~c": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => DfNewRegExpWithoutError(b)?.test(a), a, b),
        "=~u": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => DfNewRegExpWithoutError(b, "i")?.test(a), a, b),
        "c<": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a < b, a, b),
        "c<=": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a <= b, a, b),
        "c>": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a > b, a, b),
        "c>=": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a >= b, a, b),
        "u<": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.toUpperCase() < b.toUpperCase(), a, b),
        "u<=": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.toUpperCase() <= b.toUpperCase(), a, b),
        "u>": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.toUpperCase() > b.toUpperCase(), a, b),
        "u>=": (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => a.toUpperCase() >= b.toUpperCase(), a, b),

        dincludes: (a: string | null, b: string | null) => operationInners.STRING_BOOL.config._func((a, b) => (tryParseDate(a)?.toString() || "").includes(tryParseDate(b)?.toString() || ""), a, b), //cspell:ignore dincludes
        "d<": (a: string | null, b: string | null) =>
            operationInners.STRING_BOOL.config._func(
                (a: string | null, b: string | null) => {
                    const ad = tryParseDate(a);
                    const bd = tryParseDate(b);
                    return ad == null || bd == null ? null : ad < bd;
                },
                a,
                b,
            ),
        "d<=": (a: string | null, b: string | null) =>
            operationInners.STRING_BOOL.config._func(
                (a: string | null, b: string | null) => {
                    const ad = tryParseDate(a);
                    const bd = tryParseDate(b);
                    return ad == null || bd == null ? null : ad <= bd;
                },
                a,
                b,
            ),
        "d>": (a: string | null, b: string | null) =>
            operationInners.STRING_BOOL.config._func(
                (a: string | null, b: string | null) => {
                    const ad = tryParseDate(a);
                    const bd = tryParseDate(b);
                    return ad == null || bd == null ? null : ad > bd;
                },
                a,
                b,
            ),
        "d>=": (a: string | null, b: string | null) =>
            operationInners.STRING_BOOL.config._func(
                (a: string | null, b: string | null) => {
                    const ad = tryParseDate(a);
                    const bd = tryParseDate(b);
                    return ad == null || bd == null ? null : ad >= bd;
                },
                a,
                b,
            ),
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        "=~d": (a: string | null, b: string | null) => DfNewRegExpWithoutError(tryParseDate(b)?.toString()!)?.test(tryParseDate(a)?.toString()!),
    },
    LIST_BOOL: {
        config: {
            input: Type.List,
            output: Type.BooleanScalar,
            multipleTerms: false,
            _func(c: boolean, f: (a: ScalarCalculationResult, b: ScalarCalculationResult) => boolean | null, a: ListCalculationResult, b: ListCalculationResult) {
                if (a.length != b.length) {
                    return !c;
                }
                a = a.sort(operationInners.LIST_BOOL.config._sort);
                b = b.sort(operationInners.LIST_BOOL.config._sort);
                for (let i = 0; i < a.length; i++) {
                    const aF = DfStrictParseFloat(a[i]);
                    const bF = DfStrictParseFloat(b[i]);
                    if (!isNaN(aF) && !isNaN(bF)) {
                        if (f(aF, bF) != c) {
                            return !c;
                        }
                    } else {
                        const aD = tryParseDate(a[i]);
                        const bD = tryParseDate(b[i]);
                        if (aD != null && bD != null) {
                            if (f(aD.getTime(), bD.getTime()) != c) {
                                return !c;
                            }
                        } else {
                            if (f(a[i]?.toString().toUpperCase().trim(), b[i]?.toString().toUpperCase().trim()) != c) {
                                return !c;
                            }
                        }
                    }
                }
                return c;
            },
            _sort(a: ScalarCalculationResult, b: ScalarCalculationResult) {
                if (a == null || b == null) {
                    return a == null ? (b == null ? 0 : -1) : 1;
                }
                const aF = DfStrictParseFloat(a);
                const bF = DfStrictParseFloat(b);
                if (isNaN(aF) || isNaN(bF)) {
                    if (isNaN(aF) && isNaN(bF)) {
                        const aD = tryParseDate(a);
                        const bD = tryParseDate(b);
                        if (aD != null && bD != null) {
                            return aD < bD ? -1 : aD > bD ? 1 : 0;
                        } else if (aD != null) {
                            return 1;
                        } else if (bD != null) {
                            return -1;
                        } else {
                            const aU = a.toString().toUpperCase().trim();
                            const bU = b.toString().toUpperCase().trim();
                            return aU == bU ? 0 : aU < bU ? 1 : -1;
                        }
                    } else if (isNaN(aF)) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else {
                    return aF - bF;
                }
            },
        },
        "=": (a: ListCalculationResult, b: ListCalculationResult) => operationInners.LIST_BOOL.config._func(true, (a, b) => a == b, a, b),
        "<>": (a: ListCalculationResult, b: ListCalculationResult) => operationInners.LIST_BOOL.config._func(false, (a, b) => a != b, a, b),
    },
    SCALAR_BOOL: {
        config: {
            input: Type.Scalar,
            output: Type.BooleanScalar,
            multipleTerms: false,
            _func(f: (a: NonNullable<ScalarCalculationResult | Date>, b: NonNullable<ScalarCalculationResult | Date>) => boolean | null, a: ScalarCalculationResult, b: ScalarCalculationResult) {
                if (a == null || b == null || Number.isNaN(a) || Number.isNaN(b) || /^\\s*$/.test(a.toString()) || /^\\s*$/.test(b.toString())) {
                    return null;
                }
                const aF = DfStrictParseFloat(a);
                const bF = DfStrictParseFloat(b);
                if (!isNaN(aF) && !isNaN(bF)) {
                    return f(aF, bF);
                }
                const aD = tryParseDate(a);
                const bD = tryParseDate(b);
                if (aD != null && bD != null) {
                    return f(aD, bD);
                }
                return f(a.toString().toUpperCase().trim(), b.toString().toUpperCase().trim());
            },
        },
        "<": (a: ScalarCalculationResult, b: ScalarCalculationResult) => operationInners.SCALAR_BOOL.config._func((a, b) => a < b, a, b),
        "<=": (a: ScalarCalculationResult, b: ScalarCalculationResult) => operationInners.SCALAR_BOOL.config._func((a, b) => a <= b, a, b),
        ">": (a: ScalarCalculationResult, b: ScalarCalculationResult) => operationInners.SCALAR_BOOL.config._func((a, b) => a > b, a, b),
        ">=": (a: ScalarCalculationResult, b: ScalarCalculationResult) => operationInners.SCALAR_BOOL.config._func((a, b) => a >= b, a, b),
    },
    DECIMAL_DECIMAL: {
        config: {
            input: Type.DecimalScalar,
            output: Type.DecimalScalar,
            multipleTerms: true,
        },
        "/": (a: number, b: number) => a / b,
        "*": (a: number, b: number) => a * b,
        "+": (a: number, b: number) => a + b,
        "-": (a: number, b: number) => a - b,
        "%": (a: number, b: number) => a % b,
    },
    DECIMAL_LIST_BOOL: {
        config: {
            input: Type.DecimalList,
            output: Type.BooleanScalar,
            multipleTerms: false,
            _func(c: boolean, f: (a: number, b: number) => boolean | null, a: number[] | null, b: number[] | null) {
                if (a == null || b == null || /^\\s*$/.test(a as unknown as string) || /^\\s*$/.test(b as unknown as string)) {
                    return null;
                }
                if (a.length != b.length) {
                    return !c;
                }
                a = a.sort(operationInners.DECIMAL_LIST_BOOL.config._sort);
                b = b.sort(operationInners.DECIMAL_LIST_BOOL.config._sort);
                for (let i = 0; i < a.length; i++) {
                    if (f(a[i], b[i]) != c) {
                        return !c;
                    }
                }
                return c;
            },
            _sort(a: number | null, b: number | null) {
                if (a == null || b == null) {
                    return a == null ? (b == null ? 0 : -1) : 1;
                }
                return a == b ? 0 : a < b ? 1 : -1;
            },
        },
        "==": (a: number[] | null, b: number[] | null) => operationInners.DECIMAL_LIST_BOOL.config._func(true, (a, b) => a == b, a, b),
        "!=": (a: number[] | null, b: number[] | null) => operationInners.DECIMAL_LIST_BOOL.config._func(false, (a, b) => a != b, a, b),
    },
    DECIMAL_BOOL: {
        config: {
            input: Type.DecimalScalar,
            output: Type.BooleanScalar,
            multipleTerms: false,
        },
        "n<": (a: number, b: number) => a < b,
        "n<=": (a: number, b: number) => a <= b,
        "n>": (a: number, b: number) => a > b,
        "n>=": (a: number, b: number) => a >= b,
        nincludes: (a: number, b: number) => a?.toString().includes(b?.toString()), //cspell:ignore nincludes
        "=~n": (a: number, b: number) => DfNewRegExpWithoutError(b?.toString())?.test(a?.toString()),
    },
    BOOL_BOOL: {
        config: {
            input: Type.BooleanScalar,
            output: Type.BooleanScalar,
            multipleTerms: true,
        },
        "&&": (a: boolean, b: boolean) => a && b,
        "||": (a: boolean, b: boolean) => a || b,
    },
} as const;
for (const typeType of Object.keys(operationInners) as (keyof typeof operationInners)[]) {
    const { input, output, multipleTerms } = operationInners[typeType].config;
    for (const key of Object.keys(operationInners[typeType]).filter(i => i != "config")) {
        operation[key] = (calculation, context) => {
            const terms = getAllTerms(calculation, context, input);
            const a = terms.length > 0 ? combineLatest(terms) : from([[] as ListCalculationResult]);
            const result = a.pipe(
                map(resolvedTerms => {
                    //throw new Error(JSON.stringify(resolvedTerms) + ' -- '+ JSON.stringify(input)+' -- '+JSON.stringify(terms));
                    if (multipleTerms ? resolvedTerms.length >= 2 : resolvedTerms.length == 2) {
                        //@ts-ignore -- TODO
                        const r = resolvedTerms.reduce(operationInners[typeType][key]);
                        if (breakpoint(context)) debugger;
                        return r;
                    } else {
                        if (breakpoint(context)) debugger;
                        return typeType == "BOOL_BOOL" ? false : null;
                    }
                }),
            );
            const calcResult: CalculationInnerResult = { result, type: output, calculation: calculation as CalculationSetting };
            return calcResult;
        };
    }
}
const makeListBuiltinFunc = <InputType extends CalculationResult, OutputType extends CalculationResult>(inputType: TypeValue, outputType: TypeValue, func: (resolvedTerms: InputType[], context: InnerCalculationContext, calculation: CalculationSetting) => OutputType) => makeListBuiltinAsyncFunc<InputType, OutputType>(inputType, outputType, (a, b, c) => Promise.resolve(func(a, b, c)));
const makeListBuiltinAsyncFunc =
    <InputType extends CalculationResult, OutputType extends CalculationResult>(inputType: TypeValue, outputType: TypeValue, func: (resolvedTerms: InputType[], context: InnerCalculationContext, calculation: CalculationSetting) => Promise<OutputType>) =>
    (calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult => {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const a = (termedCal.terms ?? []).length == 0 ? from([[] as CalculationResult[]]) : combineLatest(...getAllTerms(termedCal, context, inputType));
        const result = a.pipe(
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
            switchMap(resolvedTerms => {
                return func(resolvedTerms as InputType[], context, calculation);
            }),
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
        );
        return { result, type: outputType, calculation: calculation };
    };
const makeArgsBuiltinFunc = <InputTypes extends CalculationResult[], OutputType extends CalculationResult>(inputTypes: TypeValue[], outputType: TypeValue, func: (resolvedTerms: InputTypes, context: InnerCalculationContext, calculation: CalculationSetting) => OutputType) => makeArgsBuiltinAsyncFunc<InputTypes, OutputType>(inputTypes, outputType, (a, b, c) => Promise.resolve(func(a, b, c)));
const makeArgsBuiltinAsyncFunc =
    <InputTypes extends CalculationResult[], OutputType extends CalculationResult>(inputTypes: TypeValue[], outputType: TypeValue, func: (resolvedTerms: InputTypes, context: InnerCalculationContext, calculation: CalculationSetting) => Promise<OutputType> | Observable<OutputType>) =>
    (calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult => {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const result = (inputTypes.length == 0 ? from([[]]) : combineLatest(getTerms(termedCal, context, ...inputTypes))).pipe(
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
            switchMap(resolvedTerms => {
                return func(resolvedTerms as InputTypes, context, calculation);
            }),
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
        );
        return { result, type: outputType, calculation: calculation as CalculationSetting };
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: Record<string, [any, number]> = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const backendQueue: [any, any, string][] = [];
let backendQueueTimerId: null | ReturnType<typeof setTimeout> = null;
export function DFClearBackendCache() {
    for (const key in cache) {
        delete cache[key];
    }
    backendQueue.splice(0, backendQueue.length);
    backendQueueTimerId = null;
}
//@ts-expect-error
function isTermedCal(i: CalculationSetting): i is ICalculationSettingWithTerms {
    return !!(i as ICalculationSettingWithTerms).terms;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtendedBackendResult = Omit<CalculationInnerResult, "result"> & { result: Observable<{ result: any; otherData: any; outerResult: any; resp: any }> };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeBackendFunc =
    (type: string | null, cachePerOperation?: boolean) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (calculation: CalculationSetting, context: InnerCalculationContext, otherData: Observable<Record<string, any>> | null | undefined, cacheKey: any | null): ExtendedBackendResult | CalculationInnerResult => {
        const elementId = context.node.id;
        const elementPath = context.node.getElementPathIndexFromRoot();
        // TODO better implementation
        const rootCalc = context.rootCalc;
        const elementCalcs = context.node.definition.settings.calculations;
        const calculationId = Object.keys(elementCalcs).find(i => elementCalcs[i][0] == rootCalc);
        const operationId = (calculation as Value).operationId ?? calculation.id;
        function walk(el: CalculationSetting): number[] | null {
            if (el == calculation) {
                return [];
            } else if (isTermedCal(el) && (el.terms?.length ?? 0) >= 0) {
                for (let i = 0; i < el.terms!.length; i++) {
                    const wR = walk(el.terms![i]);
                    if (wR != null) {
                        wR.unshift(i);
                        return wR;
                    }
                }
                return null;
            } else {
                return null;
            }
        }
        const operationPath = walk(rootCalc);
        const loopContextO = JSON.parse(
            JSON.stringify({
                __currentListIndex: (context.currentListIndex || []).map(i => i?.toString()),
                __currentListItem: (context.currentListItem || []).map(i => JSON.stringify(i)),
            }),
        );
        const operationCacheKey = cachePerOperation ? { elementId, elementPath, calculationId, operationPath, operationId } : {};
        const operationDependencies = ((calculation as BuiltinFunction).dependencies ?? []).map(dependency => _resolveCalculation({ $type: valueOperator, id: dependency.id, operationId: dependency.operationId, extraDataKey: dependency.extraDataKey } as Value /* TODO refactor? */, context, Type.StringList).pipe(map(value => ({ ...dependency, value }))));
        const otherDataO = otherData ?? from([{}]);
        const operationDependenciesO = operationDependencies.length == 0 ? from([[]]) : combineLatest(operationDependencies);
        const currentListRunningResultO = context.currentListRunningResult == null || context.currentListRunningResult.length == 0 ? from([[]]) : context.currentListRunningResult.length == 1 ? context.currentListRunningResult[0] : combineLatest(context.currentListRunningResult);
        const result = combineLatest([otherDataO, operationDependenciesO, currentListRunningResultO]).pipe(
            switchMap(async ([otherData, extraValues, currentListRunningResult]) => {
                const loopContext = {
                    ...loopContextO,
                    __currentListRunningResult: [JSON.stringify(currentListRunningResult)],
                };
                let resp = null;
                const innerCacheKey = cacheKey ? JSON.stringify({ cacheKey, otherData, extraValues, loopContext, ...operationCacheKey }) : null!;
                //if (innerCacheKey == null) debugger;
                if (cacheKey && innerCacheKey in cache && cache[innerCacheKey][1] > Date.now() - 5 * 60 * 1000) {
                    resp = cache[innerCacheKey][0];
                } else {
                    const formNode = context.node.getRoot() as FormDef;
                    const extraData = { ...loopContext };
                    for (const extraValue of extraValues) {
                        if (extraValue.extraDataKey == null || extraValue.extraDataKey == "" || extraValue.extraDataKey == "null") {
                            extraData[extraValue.id] = extraValue.value;
                        }
                    }
                    const operationData = {
                        CalculationId: calculationId,
                        OperationId: operationId,
                        OperationPath: operationPath,
                        ExtraData: extraData,
                        ...otherData,
                    };
                    const data = {
                        formName: formNode.formName,
                        formVersion: formNode.formVersion,
                        formHash: formNode.formHash,
                        segment: null,
                        skipForm: true,
                        noWrite: true,
                        asAdmin: context.adminReview || false, // TODO
                        inPreview: context.adminPreview || false, // TODO
                        testMode: context.testMode || false, // TODO
                        target: elementId,
                        targetPathArray: elementPath,
                        type: type || "resolveCalculation", // TODO magic string
                        data: JSON.stringify(operationData),
                    };
                    // TODO - this fails for preview since it fetches the last published
                    try {
                        if (context.bulkInvoke) {
                            resp = resolveablePromise();
                            backendQueue.push([data, resp, innerCacheKey]);
                            if (backendQueueTimerId == null) {
                                backendQueueTimerId = setTimeout(() => resolveBackendQueue(context), 0);
                            }
                        } else {
                            // It is important that nothing awaits prior to this for caching to work
                            resp = context
                                .urlFetcher("invokeDraft", { formId: formNode.formId! })
                                .then(url =>
                                    context.fetchXrsf(url, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify(data),
                                    }),
                                )
                                .then(async resp => {
                                    const outerResult = await (await resp).json();
                                    const result = JSON.parse(outerResult.Result);
                                    //console.log("R: "+outerResult.Result);
                                    return { outerResult, result };
                                });
                        }
                        if (cacheKey) {
                            cache[innerCacheKey] = [resp, Date.now()];
                        }
                    } catch (e) {
                        console.log(e);
                        if (breakpoint(context)) {
                            debugger;
                            throw e;
                        }
                    }
                }
                let result = null;
                let outerResult = null;
                try {
                    ({ result, outerResult } = await resp); // Yes, these () are required...
                } catch (e) {
                    console.log(e);
                    if (breakpoint(context)) {
                        debugger;
                        throw e;
                    }
                }
                if (breakpoint(context)) debugger;
                //console.log("FR: "+JSON.stringify(result));
                if (type != null) {
                    result = {
                        result,
                        otherData,
                        outerResult,
                        resp,
                    };
                } else if (result == null || result.length == null) {
                    return [];
                }
                return result;
            }),
        );
        return { result, type: Type.List, calculation: calculation as CalculationSetting };
    };

async function resolveBackendQueue(context: InnerCalculationContext) {
    backendQueueTimerId = null;
    const formNode = context.node.getRoot() as FormDef;
    const currentQueue = [...backendQueue];
    backendQueue.splice(0, backendQueue.length);
    type QueueKey = { formName: string; formVersion: string; formHash: string; skipForm: boolean; noWrite: boolean; asAdmin: boolean; inPreview: boolean; testMode: boolean };
    const groupedQueue = currentQueue.reduce(
        (a, c) => {
            const item = c[0];
            const key = {
                formName: item.formName,
                formVersion: item.formVersion,
                formHash: item.formHash,
                skipForm: item.skipForm,
                noWrite: item.noWrite,
                asAdmin: item.asAdmin,
                inPreview: item.inPreview,
                testMode: item.testMode,
            };
            const keyS = JSON.stringify(key);
            if (!(keyS in a)) {
                a[keyS] = {
                    ...key,
                    invokes: [],
                };
            }
            a[keyS].invokes.push(c);
            return a;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
        {} as Record<string, QueueKey & { invokes: any[] }>,
    );
    for (const group of Object.values(groupedQueue)) {
        // eslint-disable-next-line prefer-const
        let groupSize = 200;
        for (let invokeIndexStart = 0; invokeIndexStart < group.invokes.length; invokeIndexStart += groupSize) {
            const request = {
                ...group,
                invokes: group.invokes.slice(invokeIndexStart, invokeIndexStart + groupSize).map(i => ({
                    segment: i[0].segment,
                    target: i[0].target,
                    targetPathArray: i[0].targetPathArray,
                    type: i[0].type,
                    data: i[0].data,
                })),
            };
            const thisInvokeIndexStart = invokeIndexStart;
            context
                .urlFetcher("invokeDraftBulk", { formId: formNode.formId! })
                .then(url => {
                    return context.fetchXrsf(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(request),
                    });
                })
                .then(async resp => {
                    const outerResults = await (await resp).json();
                    if (outerResults == null || outerResults.Result == null) {
                        throw new Error(JSON.stringify(outerResults));
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return outerResults.Result.map((outerResult: any) => {
                        const result = JSON.parse(outerResult);
                        return { outerResult, result };
                    });
                })
                .then(result => {
                    for (let i = 0; i < result.length; i++) {
                        group.invokes[thisInvokeIndexStart + i][1].resolve(result[i]);
                    }
                });
        }
    }
}

const makeSortFunc =
    (reverse: boolean) =>
    (calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult => {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const r = (termedCal.terms ?? []).map(t => _resolveCalculationRaw(t, context, Type.List) as CalculationInnerResult);
        const rTypes = r.map(i => i.type);
        const rType =
            rTypes.some(i => i[0] == TypeType.String) || rTypes.every(i => i[0] == null)
                ? Type.StringList
                : rTypes.some(i => i[0] == TypeType.Decimal)
                  ? Type.DecimalList
                  : rTypes.some(i => i[0] == TypeType.Boolean)
                    ? Type.BooleanList
                    : (() => {
                          throw new Error(JSON.stringify(rTypes));
                      })();
        //throw new Error(JSON.stringify(rTypes)+" => "+JSON.stringify(rType));
        const result = (r.length == 0 ? from([[]]) : combineLatest(r.map(i => i.result))).pipe(
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
            map(resolvedTerms => {
                let result = resolvedTerms.flat();
                if (breakpoint(context)) debugger;
                if (rType[0] == TypeType.String || rType[0] == null) {
                    const possibleDecimalResult = result.map(i => [DfStrictParseFloat(i as string), i] as const);
                    if (possibleDecimalResult.every(i => !isNaN(i[0]))) {
                        result = possibleDecimalResult.sort((a, b) => (a[0] == b[0] ? 0 : a[0] < b[0] ? -1 : 1)).map(i => i[1]);
                    } else {
                        const possibleBooleanResult = result.map(i => [parseBool(i as string), i] as const);
                        if (possibleBooleanResult.every(i => i[0] != null)) {
                            result = possibleBooleanResult.sort((a, b) => (a[0] == b[0] ? 0 : a[0]! < b[0]! ? -1 : 1)).map(i => i[1]);
                        } else {
                            const possibleDateResult = result.map(i => [tryParseDate(i as string), i] as const);
                            if (possibleDateResult.every(i => i[0] != null)) {
                                result = possibleDateResult.sort((a, b) => (a[0]!.getTime() == b[0]!.getTime() ? 0 : a[0]! < b[0]! ? -1 : 1)).map(i => i[1]);
                            } else {
                                result.sort();
                            }
                        }
                    }
                } else if (rType[0] == TypeType.Decimal) {
                    result = result
                        .map(i => [DfParseFloatWithoutNan(i as string), i] as const)
                        .sort((a, b) => a[0] - b[0])
                        .map(i => i[1]);
                } else if (rType[0] == TypeType.Boolean) {
                    result.sort();
                }
                if (reverse) {
                    result.reverse();
                }
                return result;
            }),
            tap(i => {
                if (breakpoint(context)) debugger;
            }),
        );
        return { result, type: rType, calculation: calculation as CalculationSetting };
    };

const makeListIncludesFunc = (isBasic: boolean, isNumeric: boolean, isCaseSensitive: boolean, isDate: boolean) =>
    makeListBuiltinFunc<ListCalculationResult, boolean | null>(Type.List, Type.BooleanScalar, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        if (resolvedTerms.length < 2) {
            return null!;
        }
        const set = resolvedTerms.shift()!;
        const firstListIsNumeric = set.map(i => DfStrictParseFloat(i)).every(ii => !isNaN(ii));
        const secondListIsNumeric = resolvedTerms
            .flat()
            .map(j => DfStrictParseFloat(j))
            .every(jj => !isNaN(jj));

        const firstListIsDate = set.map(i => tryParseDate(i)).every(ii => ii != null);
        const secondListIsDate = resolvedTerms
            .flat()
            .map(j => tryParseDate(j))
            .every(jj => jj != null);

        if (firstListIsNumeric && secondListIsNumeric && (isBasic || isNumeric)) {
            return resolvedTerms.reduce((v, p) => v && p.every(i => set.map(i => DfStrictParseFloat(i)).includes(DfStrictParseFloat(i))), true);
        } else if (firstListIsDate && secondListIsDate && (isBasic || isDate)) {
            return resolvedTerms.reduce((v, p) => v && p.every(i => set.map(i => tryParseDate(i)?.getTime()).includes(tryParseDate(i)?.getTime())), true);
        } else if (isBasic) {
            return resolvedTerms.reduce((v, p) => v && p.every(i => set.map(i => i?.toString().toUpperCase().trim()).includes(i?.toString().toUpperCase().trim())), true);
        } else if (!isNumeric && !isDate) {
            if (isCaseSensitive) {
                return resolvedTerms.reduce((v, p) => v && p.every(i => set.includes(i)), true);
            } else {
                return resolvedTerms.reduce((v, p) => v && p.every(i => set.map(i => i?.toString().toUpperCase()).includes(i?.toString().toUpperCase())), true);
            }
        }
        return null;
    });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const builtinFunc: Record<string, (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>) => CalculationInnerResult> = {
    Sum: makeListBuiltinFunc<number[], number>(Type.DecimalList, Type.DecimalScalar, (resolvedTerms, context) => {
        const flattenTerms = resolvedTerms.flat();
        if (breakpoint(context)) debugger;
        return flattenTerms.length == 0 ? 0 : flattenTerms.reduce((a, b) => a + b);
    }),
    Concat: makeListBuiltinFunc<string[], string>(Type.StringList, Type.StringScalar, (resolvedTerms, context) => {
        const flattenTerms = resolvedTerms.flat();
        if (breakpoint(context)) debugger;
        return flattenTerms.length == 0 ? "" : flattenTerms.map(v => (v == null ? "" : v)).reduce((a, b) => a + b);
    }),
    StringLength: makeArgsBuiltinFunc<[string], number>([Type.StringScalar], Type.DecimalScalar, ([input], context) => {
        if (breakpoint(context)) debugger;
        return input?.length;
    }),
    Substring: makeArgsBuiltinFunc<[string | null, number | null, string | null], string | null>([Type.StringScalar, Type.DecimalScalar, Type.StringScalar], Type.StringScalar, ([input, index, lengthS], context) => {
        //console.log(`I: ${input}, I: ${index}, L: ${lengthS}`);
        if (breakpoint(context)) debugger;
        if (input == null) return null;
        let length = lengthS == null ? null : DfParseFloat(lengthS); // to avoid null being converted to 0
        index = index == null || isNaN(index) ? 0 : Math.round(index);
        length = length == null || isNaN(length) ? null : Math.round(length);
        if (length != null && length < 0) {
            return null;
        }
        if (index > 0) index--; // one indexed
        if (index < 0) index = input.length + index;
        //console.log(`I: ${input}, I: ${index}, L: ${length}`);
        if (index >= input.length || index < 0) {
            //console.log("Z2");
            return null;
        }
        let result;
        if (length == null) {
            result = input.substring(index);
        } else {
            result = input.substring(index, index + length);
        }
        //console.log(`I: ${input}, I: ${index}, L: ${length} => R: ${result}`);
        return result;
    }),
    StringIndexOf: makeArgsBuiltinFunc<[string | null, string | null], number | null>([Type.StringScalar, Type.StringScalar], Type.DecimalScalar, ([input, searchTerm], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || searchTerm == null) return null;
        let result = input.indexOf(searchTerm);
        if (result >= 0) result++;
        return result;
    }),
    StringStartsWith: makeArgsBuiltinFunc<[string | null, string | null], boolean | null>([Type.StringScalar, Type.StringScalar], Type.BooleanScalar, ([input, searchTerm], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || searchTerm == null) return null;
        return input.startsWith(searchTerm);
    }),
    StringEndsWith: makeArgsBuiltinFunc<[string | null, string | null], boolean | null>([Type.StringScalar, Type.StringScalar], Type.BooleanScalar, ([input, searchTerm], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || searchTerm == null) return null;
        return input.endsWith(searchTerm);
    }),
    StringReplace: makeArgsBuiltinFunc<[string | null, string | null, string | null], string | null>([Type.StringScalar, Type.StringScalar, Type.StringScalar], Type.StringScalar, ([input, search, replacement], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || search == null) return null;
        if (search.length == 0) return input;
        //@ts-expect-error
        return input.replaceAll(new DFReplace(search, true, true), replacement ?? "");
    }),
    ExtendedStringReplace: makeArgsBuiltinFunc<[string | null, string | null, string | null, string | null, boolean | null, boolean | null], string | null>([Type.StringScalar, Type.StringScalar, Type.StringScalar, Type.StringScalar, Type.BooleanScalar, Type.BooleanScalar], Type.StringScalar, ([input, search, replacement, searchType, ignoreCase, replaceAll], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || search == null) return null;
        if (search.length == 0) return input;
        if (searchType?.toUpperCase() == "REGEX") {
            const searchRegex = DfNewRegExpWithoutError(search, (replaceAll ? "g" : "") + (ignoreCase ? "i" : ""));
            return searchRegex == null ? null : input[replaceAll ? "replaceAll" : "replace"](searchRegex, replacement ?? "");
        } else {
            return input.replace(new DFReplace(search, ignoreCase ?? false, replaceAll ?? false), replacement ?? "");
        }
    }),
    ToCase: makeArgsBuiltinFunc<[string | null | undefined, string | null | undefined], string | null | undefined>([Type.StringScalar, Type.StringScalar], Type.StringScalar, ([input, type], context) => {
        if (breakpoint(context)) debugger;
        if (type?.toUpperCase() == "UPPERCASE" || type?.toUpperCase() == "UPPER") {
            return input?.toUpperCase();
        } else if (type?.toUpperCase() == "LOWERCASE" || type?.toUpperCase() == "LOWER") {
            return input?.toLowerCase();
        } else if (type?.toUpperCase() == "TITLECASE" || type?.toUpperCase() == "TITLE") {
            return input?.replace(/\b\w+\b/g, t => (t.length < 2 ? t.toUpperCase() : /^[A-Z]+$/.test(t) ? t : t.slice(0, 1).toUpperCase() + t.slice(1, t.length).toLowerCase()));
        } else {
            return input;
        }
    }),
    StringSplit: makeArgsBuiltinFunc<[string | null | undefined, string | null | undefined], string[] | null | undefined>([Type.StringScalar, Type.StringScalar], Type.StringList, ([input, splitter], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || splitter == null) return null!;
        if (splitter.length == 0) return [input];
        //console.log(`I: ${input}, S: ${splitter}`);
        const result = DFStringSplitLikeBackend(input, splitter, null, null);
        //console.log(`I: ${input}, S: ${splitter} => R: ${result}`);
        return result;
    }),
    ExtendedStringSplit: makeArgsBuiltinFunc<[string | null, string | null, string | null, string | null], string[] | null>([Type.StringScalar, Type.StringScalar, Type.StringScalar, Type.StringScalar], Type.StringList, ([input, splitter, splitterType, countS], context) => {
        //console.log(`I: ${input}, S: ${splitter}, C: ${countS}, T: ${splitterType}`);
        if (breakpoint(context)) debugger;
        if (input == null || splitter == null) return null;
        let count = countS == null ? null : DfParseFloat(countS); // to avoid null being converted to 0
        count = count == null || isNaN(count) ? null : Math.round(count);
        if (count == 0) return [];
        if (splitter.length == 0) return [input];
        const result = DFStringSplitLikeBackend(input, splitter, splitterType, count);
        //console.log(`I: ${input}, S: ${splitter}, C: ${count}, T: ${splitterType} => R: ${result}`);
        return result;
    }),
    StringEscape: makeArgsBuiltinFunc<[string | null, string | null], string | null>([Type.StringScalar, Type.StringScalar], Type.StringScalar, ([input, type], context) => {
        if (breakpoint(context)) debugger;
        if (input == null || type == null) return null;
        if (type.toUpperCase() == "HTML") {
            // TODO should this be different?
            return input.replaceAll("&", "&amp;").replaceAll("<", "&lt;").replaceAll(">", "&gt;").replaceAll('"', "&quot;").replaceAll("'", "&#039;");
        } else if (type.toUpperCase() == "HTML-DECODE") {
            const txt = document.createElement("textarea");
            txt.innerHTML = input;
            return txt.value;
        } else if (type.toUpperCase() == "HTML-ATTRIBUTE") {
            return input.replaceAll("&", "&amp;").replaceAll("<", "&lt;").replaceAll(">", "&gt;").replaceAll('"', "&quot;").replaceAll("'", "&#039;");
        } else if (type.toUpperCase() == "URL") {
            return encodeURIComponent(input);
        } else if (type.toUpperCase() == "URL-DECODE") {
            return decodeURIComponent(input);
            //} else if (type.toUpperCase() == "URL-COMPONENT") {
            //    return encodeURIComponent(input);
        } else if (type.toUpperCase() == "JS") {
            return null; // TODO?
        } else if (type.toUpperCase() == "CSV") {
            if (/[,"\n\r]/.test(input)) {
                return '"' + input.replaceAll('"', '""') + '"';
            } else {
                return input;
            }
        } else if (type.toUpperCase() == "CSV-DECODE") {
            if (input.startsWith('"') && input.endsWith('"')) {
                return input.substring(1, input.length - 1).replaceAll('""', '"');
            } else {
                return input;
            }
        } else {
            return null;
        }
    }),
    Product: makeListBuiltinFunc<number[] | null, number | null>(Type.DecimalList, Type.DecimalScalar, (resolvedTerms, context) => {
        const flattenTerms = resolvedTerms.flat();
        if (breakpoint(context)) debugger;
        return flattenTerms.length == 0 ? 0 : flattenTerms.reduce((a, b) => (a ?? 0) * (b ?? 0));
    }),
    CombineLists: makeListBuiltinFunc<ListCalculationResult, ListCalculationResult>(Type.List, Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return resolvedTerms.flat();
    }),
    Union: makeListBuiltinFunc<ListCalculationResult, ListCalculationResult>(Type.List, Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return DFUnion(resolvedTerms);
    }),
    Except: makeListBuiltinFunc<ListCalculationResult, ListCalculationResult>(Type.List, Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return DFExcept(resolvedTerms);
    }),
    Intersect: makeListBuiltinFunc<ListCalculationResult, ListCalculationResult>(Type.List, Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return DFIntersect(resolvedTerms);
    }),
    Distinct: makeListBuiltinFunc<ListCalculationResult, ListCalculationResult>(Type.List, Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        resolvedTerms.unshift([]);
        return DFUnion(resolvedTerms);
    }),
    IsSubSet: makeListBuiltinFunc<ListCalculationResult, boolean | null>(Type.List, Type.BooleanScalar, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        if (resolvedTerms.length < 2) {
            return null;
        }
        const set = resolvedTerms.shift()!;
        return resolvedTerms.reduce((v, p) => v && p.every(i => set.includes(i)), true);
    }),
    IsListBasicIncludes: makeListIncludesFunc(true, false, false, false),
    IsListCasedIncludes: makeListIncludesFunc(false, false, true, false),
    IsListUncasedIncludes: makeListIncludesFunc(false, false, false, false),
    IsListNumericIncludes: makeListIncludesFunc(false, true, false, false),
    IsListDateIncludes: makeListIncludesFunc(false, false, false, true),
    Count: makeListBuiltinFunc<ListCalculationResult, number>(Type.List, Type.DecimalScalar, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return resolvedTerms.flat().length;
    }),
    IsEmpty: makeListBuiltinFunc<string[] | null, boolean>(Type.StringList, Type.BooleanScalar, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return !resolvedTerms.flat().some(i => i != null && !/^\s*$/.test(i));
    }),
    IsEmptyList: makeListBuiltinFunc<ListCalculationResult, boolean>(Type.StringList, Type.BooleanScalar, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return resolvedTerms.reduce((v, p) => v && p.length == 0, true);
    }),
    EmptyList: makeArgsBuiltinFunc([], Type.List, (resolvedTerms, context) => {
        if (breakpoint(context)) debugger;
        return [];
    }),
    Sort: makeSortFunc(false),
    ReverseSort: makeSortFunc(true),
    FormatFixedNumber: makeArgsBuiltinFunc<[number | null, number | null], string | null>([Type.DecimalScalar, Type.DecimalScalar], Type.StringScalar, ([number, precision], context) => {
        if (breakpoint(context)) debugger;
        return number == null || precision == null || isNaN(number) || isNaN(precision) ? number?.toString() ?? null : number.toFixed(precision);
    }),
    FormatNumber: makeArgsBuiltinFunc<[number | null, string | null], string | null>([Type.DecimalScalar, Type.StringScalar], Type.StringScalar, ([number, format], context) => {
        format = (format || "").toLowerCase();
        if (number == null || isNaN(number)) {
            return number as unknown as string;
        } else if (format == "number") {
            return Intl.NumberFormat("en-US", { style: "decimal", signDisplay: "exceptZero" }).format(number).replace("+", "");
        } else if (format == "currency") {
            return Intl.NumberFormat("en-US", { style: "currency", currency: "USD", currencyDisplay: "narrowSymbol", signDisplay: "exceptZero" }).format(number).replace("+", "");
        } else if (format == "currency (rounded)") {
            return Intl.NumberFormat("en-US", { style: "currency", currency: "USD", currencyDisplay: "narrowSymbol", minimumFractionDigits: 0, maximumFractionDigits: 0, signDisplay: "exceptZero" }).format(number).replace("+", "");
        } else if (format == "percent") {
            return Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2, signDisplay: "exceptZero" }).format(number).replace("+", "");
        } else {
            return number.toString();
        }
    }),
    GetUniqueId: (calculation: CalculationSetting, contextO: InnerCalculationContext): CalculationInnerResult => {
        const stack = [...(contextO.currentListIndex ?? [])];
        return makeArgsBuiltinAsyncFunc([], Type.StringScalar, (_, context) => {
            if (breakpoint(context)) debugger;
            const virtualContext$ = context.node?.getVirtualContext$();
            return virtualContext$.pipe(
                switchMap(async virtualContext => {
                    const formId = await context.getOrCreateFormId();
                    const operationId = calculation.id;
                    const uniqueIdBasis = `F/${formId}/O/${operationId}/R/${(virtualContext ?? []).join("/")}/M/${(stack ?? []).join("/")}`;
                    const hashArray = Array.from(new Uint8Array(await crypto.subtle.digest("SHA-512", new TextEncoder().encode(uniqueIdBasis))));
                    const hashHex = hashArray
                        .map(b => b.toString(16).padStart(2, "0"))
                        .join("")
                        .toUpperCase();
                    //return uniqueIdBasis; // + " `` " + hashHex + " `` " + btoa(hashArray);
                    //return btoa(hashArray).substring(100, 110);
                    //return uniqueIdBasis + " -- " +hashHex.substring(0, 10).toUpperCase();
                    return hashHex.substring(0, 10);
                }),
            );
        })(calculation, contextO);
    },
    Now: makeArgsBuiltinAsyncFunc<[string | null], string | null>([Type.StringScalar], Type.StringScalar, ([type], context) => {
        return new Observable(function subscribe(subscriber) {
            let timerId: ReturnType<typeof setTimeout> | null = null;
            fetchMoment().then(moment => {
                const generate = () => {
                    if (breakpoint(context)) debugger;
                    let date = moment(context.now()).tz(context.timeZone); // TODO - submitted case
                    if (date == null || !date.isValid() || type == null || (type.toUpperCase() != "DATE" && type.toUpperCase() != "DATETIME")) {
                        return [null, null];
                    }
                    if (type.toUpperCase() == "DATE") {
                        date = date.startOf("day");
                    }
                    return [date, date.format("M/D/YYYY h:mm:ss A")] as const;
                };
                if (type?.toUpperCase() == "DATE") {
                    // TODO - or submitted case
                    subscriber.next(generate()[1]);
                    subscriber.complete(); // Ignoring the case that the date changes while the calculation is running...
                } else {
                    const tickOp = () => {
                        const [date, result] = generate();
                        subscriber.next(result);
                        if (date != null) {
                            timerId = setTimeout(tickOp, 1000 - date.milliseconds());
                        }
                    };
                    tickOp();
                }
            });
            return function unsubscribe() {
                clearTimeout(timerId!);
            };
        });
    }),
    DateAdd: makeArgsBuiltinAsyncFunc<[string | null, number | null, string | null], string | null>([Type.StringScalar, Type.DecimalScalar, Type.StringScalar], Type.StringScalar, async ([typeStr, operandStr, dateStr], context) => {
        const type = DfParseDateType(typeStr);
        const operand = parseFloat(operandStr as unknown as string); // TODO?
        const date = await DfParseDate(dateStr, context);
        if (breakpoint(context)) debugger;
        if (isNaN(operand) || date == null || !date.isValid() || type == null) {
            return null;
        }
        return date.add(operand, type).format("M/D/YYYY h:mm:ss A");
    }),
    DateFormat: makeArgsBuiltinAsyncFunc<[string | null, string | null], string | null>([Type.StringScalar, Type.StringScalar], Type.StringScalar, async ([dateStr, formatStr], context) => {
        let date = await DfParseDate(dateStr, context);
        const format = DfParseDateFormat(formatStr);
        if (breakpoint(context)) debugger;
        if (date == null || !date.isValid() || format == null) {
            return null;
        }
        if (format.endsWith("[Z]")) {
            date = date.utc();
        }
        return date.format(format);
    }),
    DateDifference: makeArgsBuiltinAsyncFunc<[string | null, string | null, string | null], number | null>([Type.StringScalar, Type.StringScalar, Type.StringScalar], Type.DecimalScalar, async ([typeStr, dateAStr, dateBStr], context) => {
        const type = DfParseDateType(typeStr);
        const dateA = await DfParseDate(dateAStr, context);
        const dateB = await DfParseDate(dateBStr, context);
        if (breakpoint(context)) debugger;
        if (dateA == null || !dateA.isValid() || dateB == null || !dateB.isValid() || type == null) {
            return null;
        }
        return dateA.diff(dateB, type);
    }),
    GetDaysInMonth: makeArgsBuiltinAsyncFunc<[string | null], number | null>([Type.StringScalar], Type.DecimalScalar, async ([dateStr], context) => {
        const date = await DfParseDate(dateStr, context);
        if (breakpoint(context)) debugger;
        if (date == null || !date.isValid()) {
            return null;
        }
        return date.daysInMonth();
    }),
    GetDatePart: makeArgsBuiltinAsyncFunc<[string | null, string | null], number | null>([Type.StringScalar, Type.StringScalar], Type.DecimalScalar, async ([type, dateStr], context) => {
        const date = await DfParseDate(dateStr, context);
        if (breakpoint(context)) debugger;
        if (date == null || !date.isValid() || type == null) {
            return null;
        }
        return DfGetDatePart(date, type);
    }),
    DateCompare: makeArgsBuiltinAsyncFunc<[string | null, string | null], 1 | 0 | -1 | null>([Type.StringScalar, Type.StringScalar], Type.DecimalScalar, async ([dateAStr, dateBStr], context) => {
        const dateA = await DfParseDate(dateAStr, context);
        const dateB = await DfParseDate(dateBStr, context);
        if (breakpoint(context)) debugger;
        if (dateA == null || !dateA.isValid() || dateB == null || !dateB.isValid()) {
            return null;
        }
        return dateA.isBefore(dateB) ? -1 : dateA.isSame(dateB) ? 0 : 1;
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ValueMapperV2: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const cacheKey = calculation.id != null ? { type: "ValueMapperV2" } : null;
        return makeBackendFunc(null, true)(calculation, context, otherData, cacheKey) as CalculationInnerResult;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ValueListV2: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const cacheKey = calculation.id != null ? { type: "ValueListV2" } : null;
        return makeBackendFunc(null, true)(calculation, context, otherData, cacheKey) as CalculationInnerResult;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ValueMapper: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const cacheKey = { ...calculation, terms: [...(termedCal.terms ?? [])], otherData };
        delete cacheKey.terms[3];
        delete cacheKey.id;
        const result = makeBackendFunc("resolveDatasourceLookup")(calculation, context, otherData, cacheKey) as ExtendedBackendResult;
        const columnResult = getTypedTerm(termedCal, context)(3, Type.StringScalar);
        return {
            ...result,
            result: combineLatest(result.result, columnResult.pipe(switchMap(i => i.result))).pipe(
                map(([lookupResult, columnResult]) => {
                    //console.log("ZZ: "+JSON.stringify([lookupResult, columnResult]));
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return lookupResult.result.pagedRows.map((r: any) => r[columnResult as string]);
                }),
            ),
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ValueList: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const cacheKey = { ...calculation, terms: [...(termedCal.terms ?? [])], otherData };
        delete cacheKey.terms[1];
        delete cacheKey.id;
        const result = makeBackendFunc("resolveDatasourceLookup")(calculation, context, otherData, cacheKey) as ExtendedBackendResult;
        const columnResult = getTypedTerm(termedCal, context)(1, Type.StringScalar);
        return {
            ...result,
            result: combineLatest(result.result, columnResult.pipe(switchMap(i => i.result))).pipe(
                map(([lookupResult, columnResult]) => {
                    //console.log("ZZ: "+JSON.stringify([lookupResult, columnResult]));
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return lookupResult.result.pagedRows.map((r: any) => r[columnResult as string]);
                }),
            ),
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ComplexValueMapper: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const cacheKey = calculation.id != null ? { type: "ComplexValueMapper" } : null;
        return makeBackendFunc(null, true)(calculation, context, otherData, cacheKey) as CalculationInnerResult;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BackendComputation: (calculation: CalculationSetting, context: InnerCalculationContext, otherData?: Observable<Record<string, any>>): CalculationInnerResult => {
        const cacheKey = calculation.id != null ? { type: "BackendComputation" } : null;
        return makeBackendFunc(null, true)(calculation, context, otherData, cacheKey) as CalculationInnerResult;
    },
    GroupListV2: makeArgsBuiltinAsyncFunc<[string | null], string | null>([Type.StringScalar], Type.StringScalar, async ([group]) => {
        if (group != null && group.includes("`")) {
            return group.split("`")[1];
        }
        return group;
    }),
    Sequence: makeArgsBuiltinFunc<[number | null, number | null, number | null], number[] | null>([Type.DecimalScalar, Type.DecimalScalar, Type.DecimalScalar], Type.DecimalList, ([start, end, step], context) => {
        if (breakpoint(context)) debugger;
        //context = { ...context, debug: true }
        start = start == null ? 0 : start;
        end = end == null ? 0 : end;
        step = step == null ? 1 : step;
        if (end > start != step > 0) {
            step = -1 * step;
        }
        const result = [];
        let current = start;
        while (step > 0 ? end - current > -0.00000000001 : end - current < 0.00000000001) {
            result.push(current);
            current += step;
        }
        return result;
    }),
    Map(calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult {
        const termedCal = calculation as ICalculationSettingWithTerms;
        const result = combineLatest(getAllTerms(termedCal, context, Type.Undefined, 1)).pipe(
            switchMap(async resolvedTerms => {
                if (breakpoint(context)) debugger;
                const flattenTerms = resolvedTerms.flat();
                context.currentListItem = context.currentListItem || [];
                const stackIndex = context.currentListItem.length;
                context.currentListItem.push(null);
                context.currentListIndex = context.currentListIndex || [];
                context.currentListIndex.push(null!);
                if (context.currentListItem.length != context.currentListIndex.length) {
                    throw new Error(`${context.currentListItem.length} != ${context.currentListIndex.length}`);
                }
                const result = [];
                for (let index = 0; index < flattenTerms.length; index++) {
                    context.currentListIndex[stackIndex] = index + 1;
                    context.currentListItem[stackIndex] = flattenTerms[index];
                    result.push(_resolveCalculation(termedCal.terms![0], context, Type.List));
                }
                context.currentListIndex.pop();
                context.currentListItem.pop();
                return combineLatest(result).pipe(map(i => i.flat()));
            }),
            switchMap(i => i),
        );
        return { result, type: Type.List, calculation };
    },
    Filter(calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult {
        //const result = from([[]]);
        const termedCal = calculation as ICalculationSettingWithTerms;
        const result = combineLatest(getAllTerms(termedCal, context, Type.Undefined, 1)).pipe(
            switchMap(async resolvedTerms => {
                if (breakpoint(context)) debugger;
                const flattenTerms = resolvedTerms.flat();
                context.currentListItem = context.currentListItem || [];
                const stackIndex = context.currentListItem.length;
                context.currentListItem.push(null);
                context.currentListIndex = context.currentListIndex || [];
                context.currentListIndex.push(null!);
                if (context.currentListItem.length != context.currentListIndex.length) {
                    throw new Error(`${context.currentListItem.length} != ${context.currentListIndex.length}`);
                }
                const result = [];
                for (let index = 0; index < flattenTerms.length; index++) {
                    const item = flattenTerms[index];
                    context.currentListIndex[stackIndex] = index + 1;
                    context.currentListItem[stackIndex] = item;
                    result.push(_resolveCalculation(termedCal.terms![0], context, Type.BooleanScalar).pipe(map(i => [item, i])));
                }
                context.currentListIndex.pop();
                context.currentListItem.pop();
                return combineLatest(result).pipe(
                    map(i =>
                        i
                            .filter(i => i[1])
                            .map(i => i[0])
                            .flat(),
                    ),
                );
            }),
            switchMap(i => i),
        );
        return { result, type: Type.List, calculation };
    },
    Fold(calculation: CalculationSetting, context: InnerCalculationContext): CalculationInnerResult {
        const termedCal = calculation as ICalculationSettingWithTerms;
        //const result = from([["FOLD"]]);
        const result = combineLatest(getAllTerms(termedCal, context, Type.Undefined, 1)).pipe(
            switchMap(async resolvedTerms => {
                if (breakpoint(context)) debugger;
                //context = { ...context, debug: true }
                const flattenTerms = resolvedTerms.flat();
                if (flattenTerms.length == 0) {
                    return from([[]]);
                }
                context.currentListItem = context.currentListItem || [];
                const stackIndex = context.currentListItem.length;
                context.currentListItem.push(null);
                context.currentListIndex = context.currentListIndex || [];
                context.currentListIndex.push(null!);
                context.currentListRunningResult = context.currentListRunningResult || [];
                context.currentListRunningResult.push(null!);
                if (context.currentListItem.length != context.currentListIndex.length) {
                    throw new Error(`${context.currentListItem.length} != ${context.currentListIndex.length}`);
                }
                //if (context.currentListItem.length != context.currentListRunningResult.length) {
                //    throw new Error(`${context.currentListItem.length} != ${context.currentListRunningResult.length}`);
                //}
                let result: Observable<CalculationResult> = (context.currentListRunningResult[stackIndex] = from([flattenTerms[0]]));
                for (let index = 1; index < flattenTerms.length; index++) {
                    const item = flattenTerms[index];
                    context.currentListIndex[stackIndex] = index + 1;
                    context.currentListItem[stackIndex] = item;
                    result = context.currentListRunningResult[stackIndex] = _resolveCalculation(termedCal.terms![0], context, Type.Scalar);
                }
                context.currentListIndex.pop();
                context.currentListItem.pop();
                context.currentListRunningResult.pop();
                return result;
            }),
            switchMap(i => i),
        );
        return { result, type: Type.Scalar, calculation };
    },
};

function getTerms(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext, ...types: TypeValue[]) {
    const f = getTerm(calculation, context);
    return types.map((type, index) => f(index, type));
}
function getAllTerms(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext, type: TypeValue, skip?: number) {
    const f = getTerm(calculation, context);
    const r = calculation.terms!.map((_, index) => (skip != null && skip > index ? null! : f(index, type)));
    return skip != null ? r.filter((v, i) => i >= skip) : r;
}

function getTerm(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext) {
    return (key: number, type: TypeValue) => {
        if (calculation.terms!.length > key) {
            return _resolveCalculation(calculation.terms![key], context, type);
        } else {
            return from([null! as CalculationResult]);
        }
    };
}
function getTypedTerm(calculation: ICalculationSettingWithTerms, context: InnerCalculationContext) {
    return (key: number, type: TypeValue): Observable<CalculationInnerResult> => {
        if (calculation.terms!.length > key) {
            return _resolveCalculationTyped(calculation.terms![key], context, type);
        } else {
            return from([{ result: null, type: Type.Undefined } as unknown as CalculationInnerResult]);
        }
    };
}

function DFUnion(listOfLists: ListCalculationResult[]) {
    if (listOfLists.length == 1) {
        return listOfLists[0];
    }
    // TODO use Map instead?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hash = {} as Record<any, true>,
        union = [] as ListCalculationResult;
    listOfLists.forEach(function (l) {
        l.forEach(function (item) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!hash[item as any]) {
                union.push(item);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                hash[item as any] = true;
            }
        });
    });
    return union;
}

function DFExcept(listOfLists: ListCalculationResult[]) {
    if (listOfLists.length == 0) {
        return [];
    }
    if (listOfLists.length == 1) {
        return listOfLists[0];
    }
    // TODO use Map instead?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hash = {} as Record<any, true>;
    listOfLists[0].forEach(function (item) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hash[item as any] = true;
    });
    listOfLists.slice(1).forEach(function (list) {
        list.forEach(function (item) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (hash[item as any]) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                delete hash[item as any];
            }
        });
    });
    return listOfLists[0].filter(function (item) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (hash[item as any]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete hash[item as any];
            return 1;
        } else {
            return 0;
        }
    });
}

function DFIntersect(listOfLists: ListCalculationResult[]) {
    if (listOfLists.length == 0) {
        return [];
    }
    if (listOfLists.length == 1) {
        return listOfLists[0];
    }
    // TODO use Map instead?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hash = {} as Record<any, number>;
    listOfLists.slice(1).forEach(function (l) {
        l.forEach(function (item) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (hash[item as any]) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                hash[item as any] += 1;
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                hash[item as any] = 1;
            }
        });
    });
    return listOfLists[0].filter(function (item) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (hash[item as any]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const result = hash[item as any] === listOfLists.length - 1;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete hash[item as any];
            return result;
        } else {
            return 0;
        }
    });
}

function DFStringSplitLikeBackend(input: string, splitter: string, splitterType: string | null, count: number | null) {
    //const logP = `I: ${input}, S: ${splitter}, ST: ${splitterType}, C: ${count}`;
    //console.log(logP);
    if (count == 1) return [input];
    const result = [];
    const isRegex = splitterType?.toUpperCase() == "REGEX";
    const isCounting = count != null && count >= 0;
    const matchRegex = isRegex ? DfNewRegExpWithoutError(splitter, "g") : null;
    if (isRegex && matchRegex == null) return null;
    let foundCount = 0;
    let pos = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        let nextPos;
        let splitLen;
        if (isRegex) {
            const matchResultArray = matchRegex!.exec(input);
            if (matchResultArray == null) {
                nextPos = -1;
                splitLen = 0;
            } else {
                splitLen = matchResultArray[0].length;
                nextPos = matchRegex!.lastIndex - splitLen;
                result.push(input.substring(pos, nextPos));
                for (let i = 1; i < matchResultArray.length; i++) {
                    result.push(matchResultArray[i]);
                }
            }
        } else {
            nextPos = input.indexOf(splitter, pos);
            splitLen = splitter.length;
            if (nextPos != -1) {
                result.push(input.substring(pos, nextPos));
            }
        }
        //console.log(`${logP} => P: ${pos}, NP: ${nextPos}, SL: ${splitLen}`);
        if (nextPos == -1) {
            //console.log(`${logP} => Not Found Term at ${pos}`);
            result.push(input.substring(pos));
            break;
        }
        pos = nextPos + splitLen;
        foundCount++;
        if (foundCount > 1000) throw new Error("Infinite loop?");
        if (isCounting) {
            if (foundCount + 1 >= count) {
                //console.log(`${logP} => Count Term at ${pos}`);
                result.push(input.substring(pos));
                break;
            }
        }
    }
    return result;

    //let result;
    //if (splitterType?.toUpperCase() == "REGEX") {
    //    let splitterRegex = DfNewRegExpWithoutError(splitter);
    //    if (splitterRegex == null) return null;
    //    if (count != null && count >= 0) {
    //        result = input.split(splitterRegex, count);
    //    } else {
    //        result = input.split(splitterRegex);
    //    }
    //} else {
    //    if (count != null && count >= 0) {
    //        result = input.split(splitter, count);
    //    } else {
    //        result = input.split(splitter);
    //    }
    //}
}

class DFReplace {
    ignoreCase: boolean;
    replaceAll: boolean;
    match: string;
    constructor(match: string, ignoreCase: boolean, replaceAll: boolean) {
        this.match = match;
        this.ignoreCase = ignoreCase;
        this.replaceAll = replaceAll;
    }
    [Symbol.replace](input: string, replacement: string) {
        if (this.ignoreCase) {
            // Based on https://stackoverflow.com/a/49460599
            const strLower = input.toUpperCase();
            const findLower = String(this.match).toUpperCase();
            let strTemp = input.toString();

            if (this.replaceAll) {
                let count = 0;
                let pos = strLower.length;
                while ((pos = strLower.lastIndexOf(findLower, pos)) != -1) {
                    strTemp = strTemp.substr(0, pos) + replacement + strTemp.substr(pos + findLower.length);
                    if (pos == 0) break;
                    pos--;
                    if (count++ > 1000) throw new Error("Infinite loop?");
                }
            } else {
                const pos = strLower.indexOf(findLower);
                if (pos != -1) {
                    strTemp = strTemp.substr(0, pos) + replacement + strTemp.substr(pos + findLower.length);
                }
            }
            return strTemp;
        } else {
            if (this.replaceAll) {
                return input.replaceAll(this.match, replacement);
            } else {
                return input.replace(this.match, replacement);
            }
        }
    }
}

////export function DfGetAllTableValues(fetchXrsfFunc, urlFetcher, tableName, testMode, resultKey) {
////    return DfGetAllAreaTableValues(fetchXrsfFunc, urlFetcher, null, tableName, testMode, resultKey);
////}
////const tableCaches = {};
////export function DFClearTableCache() {
////    for (let key in tableCaches) {
////        delete tableCaches[key];
////    }
////}
////export function DfGetAllAreaTableValues(fetchXrsfFunc, urlFetcher, areaCode, tableName, testMode, resultKey) {
////    const tableCache = _DFResolveGetTableCache(areaCode, tableName, testMode);
////    //@ts-ignore
////    let allPromise = tableCache[null];
////    if (allPromise == null) {
////        //@ts-ignore
////        tableCache[null] = allPromise = (async () => {
////            const url = await urlFetcher("datasourceFetchData", { tenantCode: areaCode, tableKey: tableName, testMode: testMode });
////            const tableData = await fetch(url).then(r => r.json());
////            return tableData;
////        })();
////    }
////    return _DFResolveTableLookup([allPromise], resultKey);
////}
////function DfGetTableValue(fetchXrsfFunc, urlFetcher, tableName, testMode, resultKey, keyKey, keyValues) {
////    return DfGetAreaTableValue(fetchXrsfFunc, urlFetcher, null, tableName, testMode, resultKey, keyKey, keyValues);
////}
////function DfGetAreaTableValue(fetchXrsfFunc, urlFetcher, areaCode, tableName, testMode, resultKey, keyKey, keyValues) {
////    if (
////        keyValues.filter(function (v) {
////            return v != null && !/^\s*$/.test(v);
////        }).length == 0
////    ) {
////        return Promise.resolve([]);
////    }
////    const tableCache = _DFResolveGetTableCache(areaCode, tableName, testMode);
////    let tableKeyCache = tableCache[keyKey];
////    if (tableKeyCache == null) {
////        tableKeyCache = tableCache[keyKey] = {};
////    }
////    const keyCacheStr = JSON.stringify(keyValues);
////    let promise = tableKeyCache[keyCacheStr];
////    if (promise == null) {
////        promise = tableKeyCache[keyCacheStr] = (async function () {
////            const url = await urlFetcher("datasourceFetchRows", { tenantCode: areaCode });
////            const data = {
////                tableKey: tableName,
////                testMode: testMode,
////                rowKey: keyKey,
////                rowMatches: keyValues,
////            };
////            const rows = await fetchXrsfFunc(url, {
////                method: "POST",
////                headers: {
////                    "Content-Type": "application/json",
////                },
////                body: JSON.stringify(data),
////            }).then(r => r.json());
////
////            return rows;
////        })();
////    }
////    return _DFResolveTableLookup([promise], resultKey);
////}
////function _DFResolveGetTableCache(areaCode, tableName, testMode) {
////    let areaCache = tableCaches[areaCode];
////    if (areaCache == null) {
////        areaCache = tableCaches[areaCode] = {};
////    }
////    let tableCache = areaCache[tableName];
////    if (tableCache == null) {
////        tableCache = areaCache[tableName] = {};
////    }
////    let modeCache = tableCache[testMode];
////    if (modeCache == null) {
////        modeCache = tableCache[testMode] = {};
////    }
////    return modeCache;
////}
////function _DFResolveTableLookup(promiseLists, resultKey) {
////    return Promise.all(promiseLists).then(function (input) {
////        //throw new Error(resultKey+" => "+JSON.stringify(input));
////        return [].concat.apply(
////            [],
////            input.map(i => i.map(v => v[resultKey])),
////        );
////    });
////}

function DfParseDateType(value: string | null | undefined) {
    if (value == null) {
        return null;
    }
    value = value.toUpperCase();
    return value == "YEAR" || value == "YEARS" ? "years" : value == "MONTH" || value == "MONTHS" ? "months" : value == "DAY" || value == "DAYS" ? "days" : value == "HOUR" || value == "HOURS" ? "hours" : value == "MINUTE" || value == "MINUTES" ? "minutes" : value == "SECOND" || value == "SECONDS" ? "seconds" : null;
}

const formatMap = {
    "M-d-yyyy": "MM-DD-YYYY",
    "M/d/yyyy": "M/D/YYYY",
    "M/d/yyyy h:mm:ss tt": "M/D/YYYY h:mm:ss A",
    "M/d/yyyy h:mm:ss tt zzzz": "M/D/YYYY h:mm:ss A zz",
    "MMMM d, yyyy": "MMMM D, YYYY",
    "dddd, MMMM d, yyyy": "dddd, MMMM D, YYYY",
    "h:mm:ss tt": "h:mm:ss A",
    "h:mm tt": "h:mm A",
    "h:mm:ss tt zzzz": "h:mm:ss A zz",
    "M/d/yyyy H:mm:ss": "M/D/YYYY H:mm:ss",
    "H:mm:ss": "H:mm:ss",
    "yyyy-MM-dd": "YYYY-MM-DD",
    'yyyy-MM-dd"T"HH:mm:ss': "YYYY-MM-DD[T]HH:mm:ss",
    'yyyy-MM-dd"T"HH:mm:ss"Z"': "YYYY-MM-DD[T]HH:mm:ss[Z]",
};
function DfParseDateFormat(format: string | null) {
    if (format! in formatMap) {
        return formatMap[format as keyof typeof formatMap];
    }
    return null;
}

async function DfParseDate(value: string | null | undefined, context: CalculationContext) {
    if (value == null) {
        return null;
    }
    return (await fetchMoment())(parseDate(value, context.schemaVersion)).tz(context.timeZone, true);
}

export function parseBool(value: string | null | undefined | boolean | number) {
    if (value == null) {
        return null;
    }
    if (typeof value == "number") {
        return value != 0;
    }
    const valueStr = value.toString().toLowerCase();
    return valueStr == "1" || valueStr == "true" ? 1 : valueStr == "0" || valueStr == "false" ? 0 : null;
}

export function DfParseFloat(value: string | null | undefined | number | boolean) {
    // Remove spaces, dollar sign, commas, etc before parsing
    value = value == null ? null : value.toString().replace(/[^+0-9.-]+/g, "");
    return parseFloat(value!);
}

export function DfParseFloatWithoutNan(value: string | null | undefined | number | boolean) {
    const result = DfParseFloat(value);
    return isNaN(result) ? 0 : result;
}

//export function DfStrictParseFloatWithoutNan(value) {
//    const result = DfStrictParseFloat(value);
//    return isNaN(result) ? 0 : result;
//}

function DfGetDatePart(value: Moment.Moment, typeI: string | null | undefined) {
    if (value == null || typeI == null) {
        return null;
    }
    typeI = typeI.toUpperCase();
    const type =
        typeI == "YEAR" || typeI == "YEARS"
            ? "year"
            : typeI == "MONTH" || typeI == "MONTHS"
              ? "month"
              : typeI == "DAY" || typeI == "DAYS"
                ? "date" // Not day - that's day of week
                : typeI == "HOUR" || typeI == "HOURS"
                  ? "hour"
                  : typeI == "MINUTE" || typeI == "MINUTES"
                    ? "minute"
                    : typeI == "SECOND" || typeI == "SECONDS"
                      ? "second"
                      : null;
    let result = value[type!]() as number;
    // JS is 0-indexed, backend is 1-indexed - standardize on 1-indexed
    if (type == "month") {
        result++;
    }
    return result;
}

function DfNewRegExpWithoutError(input: string, flags?: string) {
    try {
        return new RegExp(input, flags);
    } catch (e) {
        if (e instanceof SyntaxError) {
            return null;
        } else {
            throw e;
        }
    }
}
